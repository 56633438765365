import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getTransactionList } from '../middleWares/getTransactionList'
import { SORT_DIRECTION, StatusTypesEnum } from '../../types/CommonTypes'
import { type IStatusResponse } from './viewPropertyInventorySlice'
import { DEFAULT_SORTING_ORDER } from '../../Constants'

export interface TransactionListType {
  transId: string
  recordNo: number
  propertyName: string
  transactionType: string
  apn: string
  serialNumber: string
  documentNo: string
  transactionStatus: string
  workOrderNumber: string
  jobOrder: string
  preparedBy: string
  refId?: number
}

export type TransactionSortOrderObjectType = {
  [k in keyof TransactionListType]: SORT_DIRECTION
}

export interface ITransaction {
  transactionList: TransactionListType[]
  transactionListError: boolean
  pageCount: number
  status: IStatusResponse
  sortingOrder: TransactionSortOrderObjectType
  totalTransactions: number
}

const sortingOrderInitialData: TransactionSortOrderObjectType = {
  transId: DEFAULT_SORTING_ORDER,
  recordNo: DEFAULT_SORTING_ORDER,
  propertyName: DEFAULT_SORTING_ORDER,
  transactionType: DEFAULT_SORTING_ORDER,
  apn: DEFAULT_SORTING_ORDER,
  serialNumber: DEFAULT_SORTING_ORDER,
  documentNo: DEFAULT_SORTING_ORDER,
  transactionStatus: DEFAULT_SORTING_ORDER,
  workOrderNumber: DEFAULT_SORTING_ORDER,
  jobOrder: DEFAULT_SORTING_ORDER,
  preparedBy: DEFAULT_SORTING_ORDER
}

export const transactionInitialData: ITransaction = {
  transactionList: [],
  transactionListError: false,
  status: { type: undefined, message: '' },
  pageCount: 0,
  sortingOrder: { ...sortingOrderInitialData },
  totalTransactions: 0
}

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: transactionInitialData,
  reducers: {
    setSorting (state, action: PayloadAction<TransactionSortOrderObjectType>) {
      const [[columnName, sortOrder]] = Object.entries(action.payload)
      const updatedSortOrder = sortOrder === SORT_DIRECTION.ASC ? SORT_DIRECTION.DSC : SORT_DIRECTION.ASC
      state.sortingOrder = { ...sortingOrderInitialData, [columnName]: updatedSortOrder }
    },
    resetSortingOrder (state) {
      state.sortingOrder = { ...sortingOrderInitialData }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionList.pending, (state) => {
      state.status.type = StatusTypesEnum.LOADING
      state.status.message = transactionInitialData.status.message
    })

    builder.addCase(getTransactionList.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.transactionList = action.payload.data
      } else {
        state.transactionList = []
      }
      state.pageCount = action.payload.totalPages
      state.status.type = StatusTypesEnum.SUCCESS
      state.status.message = action.payload.message
      state.totalTransactions = action.payload.totalResult
    })

    // TODO: Error handling
    builder.addCase(getTransactionList.rejected, (state, action) => {
      state.transactionListError = true
      state.status.type = StatusTypesEnum.ERROR
      state.status.message = action.error.message
    })
  }
})

export const { setSorting, resetSortingOrder } = transactionSlice.actions
export default transactionSlice.reducer
